import React from "react";
import { graphql } from "gatsby";
import { Container } from "react-bootstrap";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import TPArticle from "../components/Common/TPArticle";

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;

const PageTemplate = ({ location, data }) => {
  const page = data.markdownRemark;

  return (
    <Layout location={location}>
      <SEO title={page.frontmatter.title} />

      <main role="main" style={{ marginTop: "56px" }}>
        <Container>
          <section
            style={{
              maxWidth: "760px",
              minHeight: "600px",
              margin: "0 auto",
              backgroundColor: "#ffffff",
            }}
          >
            <TPArticle post={page} />
          </section>
        </Container>
      </main>
    </Layout>
  );
};

export default PageTemplate;
